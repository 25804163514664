import React from "react";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  useColorMode,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  HiHome,
  // HiDocument,
  HiStar,
  HiAcademicCap,
  HiMail,
  HiPencil,
  HiMoon,
  HiSun,
  HiDocument,
} from "react-icons/hi";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// Got this component from Choc UI and customized it
export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();
  return (
    <React.Fragment>
      <chakra.header bg={bg} w="full" px={{ base: 2, sm: 4 }} py={4}>
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <HStack display="flex" spacing={3} alignItems="center">
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />
              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={4}
                pb={4}
                m={2}
                bg={bg}
                spacing={2}
                rounded="sm"
                shadow="sm"
                zIndex={1}
              >
                <CloseButton
                  aria-label="Close menu"
                  justifySelf="self-start"
                  onClick={mobileNav.onClose}
                />
                <Link to="/">
                  <Button w="full" variant="ghost" leftIcon={<HiHome />}>
                    Home
                  </Button>
                </Link>
                <Link to="/#skills">
                  <Button w="full" variant="ghost" leftIcon={<HiAcademicCap />}>
                    Skills
                  </Button>
                </Link>
                <Link to="/#about">
                  <Button w="full" variant="ghost" leftIcon={<HiStar />}>
                    About Me
                  </Button>
                </Link>
                <Link to="/#contact">
                  <Button w="full" variant="ghost" leftIcon={<HiMail />}>
                    Contact
                  </Button>
                </Link>
                <Link to="/projects">
                  <Button w="full" variant="ghost" leftIcon={<HiDocument />}>
                    Projects
                  </Button>
                </Link>
                <Link to="/blog">
                  <Button w="full" variant="ghost" leftIcon={<HiPencil />}>
                    Blog
                  </Button>
                </Link>
              </VStack>
            </Box>
            <chakra.a
              href="/"
              title="Home Page"
              display="flex"
              alignItems="center"
            >
              <StaticImage
                src="../images/logo.png"
                placeholder="blurred"
                height={40}
                width={33}
                alt="Logo"
              />
              <VisuallyHidden>Akaash Sinha</VisuallyHidden>
            </chakra.a>
            <HStack spacing={3} display={{ base: "none", md: "inline-flex" }}>
              <Link to="/">
                <Button variant="ghost" leftIcon={<HiHome />} size="md">
                  Home
                </Button>
              </Link>
              <Link to="/#skills">
                <Button variant="ghost" leftIcon={<HiAcademicCap />} size="md">
                  Skills
                </Button>
              </Link>
              <Link to="/#about">
                <Button variant="ghost" leftIcon={<HiStar />} size="md">
                  About Me
                </Button>
              </Link>
              <Link to="/#contact">
                <Button variant="ghost" leftIcon={<HiMail />} size="md">
                  Contact
                </Button>
              </Link>
              {/* <Button variant="ghost" leftIcon={<HiDocument />} size="sm">
                <Link to="/projects">Projects</Link>
              </Button> */}
              <Link to="/projects">
                <Button variant="ghost" leftIcon={<HiDocument />} size="md">
                  Projects
                </Button>
              </Link>
              <Link to="/blog">
                <Button variant="ghost" leftIcon={<HiPencil />} size="md">
                  Blog
                </Button>
              </Link>
            </HStack>
          </HStack>
          <IconButton
            aria-label="Toggle for day/night mode"
            onClick={toggleColorMode}
            variant="ghost"
            icon={
              colorMode === "light" ? <HiMoon size={25} /> : <HiSun size={25} />
            }
          />
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
}
